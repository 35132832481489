"use client";

import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { cn } from "@/lib/utils";
import { useDelayedToggleState, useOutOfBoundsHitToggle, useWindowResizeSizing } from "@/lib/hooks";
import type { Basket, BasketItem, Service } from "@/types";

import {
	useGetCustomServicesByIdsQuery,
	useGetServicesByIdsQuery,
} from "@/features/services/serviceApiInjection";

import LoadingSpinner from "@/components/loadingComponents/loadingSpinner";
import { Button } from "@/components/ui/button";

import basketIcon from "/public/assets/home/navigation/cart.png";
import basketClickedIcon from "/public/assets/home/navigation/clicked/cart.png";

export default function NavBasketButton() {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [clicked, showDropdown, setDropdownVisibility] = useDelayedToggleState(false, 300);
	const basket = useSelector((state: { basket: Basket }) => state.basket);

	const basketEntires = useMemo(() => Object.entries(basket.items), [basket.items]);

	useOutOfBoundsHitToggle(wrapperRef, [clicked, setDropdownVisibility]);

	return (
		<div ref={wrapperRef} className="group sm:relative">
			<button
				role="combobox"
				aria-controls="basket-dropdown"
				aria-expanded={showDropdown}
				aria-label="Basket"
				className="relative flex size-10 items-center justify-center"
				onClick={() => setDropdownVisibility((old) => !old)}
			>
				<Image
					src={clicked ? basketClickedIcon : basketIcon}
					alt=""
					aria-hidden
					sizes="10vw"
					className="-mt-1.5 w-7"
				/>

				{basketEntires.length > 0 && (
					<div className="absolute right-0.5 top-0.5 inline-flex size-4 items-center justify-center rounded-full bg-tertiary text-xxsm text-secondary-foreground">
						<span>{basketEntires.length}</span>
					</div>
				)}
			</button>
			{showDropdown && (
				<div
					id="basket-dropdown"
					onClick={() => setDropdownVisibility(false)}
					className={cn(
						"absolute right-0 top-[90px] w-screen opacity-0 duration-300 sm:-right-2 sm:top-16 sm:w-96",
						{ "opacity-100": clicked },
					)}
				>
					{basketEntires.length > 0 ? (
						<BasketDropdown items={basketEntires} />
					) : (
						<BasketDropdownEmpty />
					)}
				</div>
			)}
		</div>
	);
}

function BasketDropdownEmpty() {
	return (
		<div className="flex h-32 flex-col items-center justify-center gap-1 border border-secondary/10 bg-background">
			<Image src={basketIcon} alt="" aria-hidden className="w-8" sizes="10vw" />
			<span className="text-base font-medium">Your basket is empty</span>
		</div>
	);
}

type BasketDropdownProps = { items: [string, BasketItem][] };
function BasketDropdown({ items }: BasketDropdownProps) {
	const dropdownMaxHeight = useWindowResizeSizing(0, -100);

	const ids = useMemo(() => items.map(([x, { serviceId }]) => serviceId).join(","), [items]);
	const { data, isLoading } = useGetServicesByIdsQuery(ids);
	const { data: customData, isLoading: isCustomLoading } = useGetCustomServicesByIdsQuery(ids);

	const keyedData: Record<string, Service> = useMemo(() => {
		if (data === undefined || customData === undefined) return {};

		return data.docs.concat(customData.docs).reduceRight(
			(prev, x) => {
				prev[x._id] = x;
				return prev;
			},
			{} as Record<string, Service>,
		);
	}, [data, customData]);

	const empty = data?.docs.length === 0 && customData?.docs.length === 0;
	const baseClass = clsx(
		"flex min-h-32 flex-col overflow-y-auto border border-secondary/10 bg-background",
		{ "items-center justify-center h-32": empty || isLoading || isCustomLoading },
	);

	if (isLoading || !data || isCustomLoading || !customData) {
		return (
			<div className={baseClass}>
				<LoadingSpinner />
			</div>
		);
	}

	if (empty) {
		return <BasketDropdownEmpty />;
	}

	return (
		<div
			className={baseClass}
			style={{ maxHeight: `${dropdownMaxHeight.current}px` }}
			// Note(Curstantine): stop bubbling click events on this div to the parent to prevent the dropdown from closing
			// unless of course if it's an Link element.
			onClick={(e) => {
				const { tagName } = e.target as Element;
				if (tagName !== "A") e.stopPropagation();
			}}
		>
			<div className="inline-flex h-12 items-center justify-center gap-4 px-6">
				<Image src={basketIcon} alt="" aria-hidden className="-mt-1 size-7" sizes="10vw" />
				<span className="text-md font-medium">Basket</span>
			</div>

			{items.map(([basketId, { serviceId }]) => {
				const { title, description } = keyedData[serviceId];

				return (
					<div
						key={basketId}
						className="flex select-none flex-col border-y border-secondary/[0.02] px-6 py-2"
					>
						<div className="text-sm font-medium leading-tight">{title}</div>
						<div className="truncate text-xs">{description}</div>
					</div>
				);
			})}

			<div className="flex w-full justify-center px-8 py-4">
				<Link href="/basket">
					<Button variant="accent" className="pointer-events-none shadow-md">
						Go to Basket
					</Button>
				</Link>
			</div>
		</div>
	);
}
